import _jiti from "../dist/jiti";
import _babel from "../dist/babel";
var exports = {};
function onError(err) {
  throw err; /* ↓ Check stack trace ↓ */
}

exports = function (filename, opts) {
  const jiti = _jiti;
  opts = {
    onError,
    ...opts
  };
  if (!opts.transform) {
    opts.transform = _babel;
  }
  return jiti(filename, opts);
};
export default exports;